<template>
  <div>
    <el-row :gutter="10"
            v-if="form">
      <el-button type="primary"
                 @click="$router.go(-1)">返回</el-button>
      <el-col>
        <span>考点名：</span>{{tableData.knowledge_data.knowledge_desc}}
      </el-col>
    </el-row>
    <el-table border
              stripe
              highlight-current-row
              :max-height="$store.state.tableHeight"
              :data="tableData.user_data.list"
              style="width: 100%">
      <el-table-column prop="id"
                       align="center"
                       width="100"
                       label="学生ID" />
      <el-table-column prop="truename"
                       align="center"
                       label="学生姓名" />
      <el-table-column prop="count"
                       align="center"
                       label="学生做题次数" />
      <el-table-column prop="score"
                       align="center"
                       label="学生得分率" />
    </el-table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: {
        knowledge_data: {
          knowledge_desc: '',
        },
        user_data: {
          list: []
        },

      },
      form: null
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    initData () {
      this.form = JSON.parse(this.$route.query.form)
      this.$http({
        url: '/api/v1/combination/user_knowldge',
        method: 'get',
        params: this.form
      }).then(res => {
        this.tableData = res.data
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.el-col {
  font-size: 16px;
  padding: 10px;
  span {
    font-weight: bold;
  }
}
</style>
